body {
  font-size: 15px;
  background-color: #f6f6f6;
  font-weight: 500;
}
.table-container {
  display: flex;
  justify-content: center;
}

.select-super-container {
  display: flex;
  justify-content: center;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: royalblue;
  color: #fff;
  height: 5rem;

  a {
    color: #fff;
    text-decoration: none;
    margin: 2rem;
  }
}

.btn {
  border-radius: 0px;
  background-color: #3c75b0;
  text-transform: uppercase;
  font-weight: 650;
  color: #f8f9fb;

  margin: 1rem;
  padding: 0.5rem;
  cursor: pointer;
}

.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

table {
  border: 1px solid #e7e7e9;
  text-align: left;
  border-collapse: collapse;
  color: #202122;
  font-weight: 500;

  td,
  th {
    border: 1px solid #e7e7e9;
    padding: 1rem;
  }
  th {
    background-color: #f8f9fb;
    text-transform: uppercase;
  }
}

tr:nth-child(odd) {
  background-color: #f8f9fb;
}

tbody td:nth-child(1) {
  color: #3c75b0;
}

tbody td:nth-child(2),
tbody td:nth-child(3) {
  text-align: center;
}

tfoot {
  text-align: center;
}

.select-label {
  text-align: left;
}

.select-container {
  margin: 0.3rem;
}

select,
input {
  padding: 0.2rem;
  cursor: pointer;
  font-size: 15px;
  border-radius: 0;
  background-color: #f6f6f6;
  border-color: #3c75b0;
  color: #3c75b0;
  padding: 0.3rem;
  font-weight: 500;
}

.select-label {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 600;
}

h2,
.input-label {
  text-transform: uppercase;
  border-bottom: 1px solid #c7c7c9;
}
.input-label {
  font-size: 1.4em;
  font-weight: 700;
}
.select-ultra-container,
.add-store-container {
  border: 1px solid #c7c7c9;
  padding: 1rem 2rem;
  padding-top: 0;
  margin: 3rem 1rem;
  margin-bottom: 1rem;
}
.add-store-container {
  max-width: 500px;
  margin: 3rem auto;
  margin-bottom: 1rem;
}
.period-div {
  display: flex;
}

h1 {
  margin-top: 3rem;
}

.example {
  color: #c7c7c9;
  font-size: 0.8em;
}
.nav-label {
  margin-right: 1rem;
}
.nav-select-container {
  display: flex;
  align-items: center;
  border: 1px solid #c7c7c9;
  padding: 0.3rem;
  select {
    height: 30px;
  }
}

.hide {
  display: none;
}

body {
  padding-bottom: 10rem;
}
.login {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  border: 1px solid #c7c7c9;
  margin: auto auto;
  padding: 3rem;
  margin-top: 10rem;

  input {
    text-align: center;
  }
}

.err-div-login,
.err-div,
.success-div {
  background-color: #ffbbbb;
  color: red;
  padding: 0.3rem;
  max-width: 200px;
  margin: 0.3rem auto;
}
.success-div {
  background-color: #bbffbb;
  color: #00b33c;
}

.currency-input {
  max-width: 50px;
  text-transform: uppercase;
}

.campaign-name {
  background-color: #e7e7e9;
  border: 1px solid #c7c7c9;
  padding: 2rem;
  cursor: pointer;
  text-align: left;
  color: #3c75b0;
  max-width: 400px;
  margin: 1rem auto;
  margin-bottom: 0;
}

.adset-name {
  text-align: left;
  border: 1px solid #c7c7c9;
  background-color: #c7c7c9;
  padding: 1.5rem 3rem;
  cursor: pointer;
  border-collapse: collapse;
  color: #202122;
  font-weight: 500;
  max-width: 368px;
  margin: 0 auto;
  margin-bottom: 0;
}

.show {
  display: block;
}

/* .c-form {
  max-width: 500px;
  margin: 2rem auto;
} */

.c-form {
  margin-top: 5rem;
}

.currency-form,
.currency-list {
  margin: 1rem auto;
}

.nav-load-p {
  cursor: pointer;
  border: 2px solid white;
  text-transform: uppercase;
  padding: 0.5rem;
  margin: 1rem;
}

.tbl-input-spend {
  width: 50px;
}

.totals {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.period-choice {
  span {
    cursor: pointer;
    margin: 0;
    font-size: 20px;
    color: #999;
  }
}

.chosen {
  color: #333 !important;
}
